<script setup>
import {Head, useForm} from '@inertiajs/vue3';
import AuthenticationCard from '@/Components/AuthenticationCard.vue';
import Logo from '@/Components/Logo.vue';
import Checkbox from '@/Components/Checkbox.vue';
import PrimaryButton from '@/Components/PrimaryButton.vue';
import TextInput from '@/Components/Fields/TextInput.vue';

defineProps({
    canResetPassword: Boolean,
    status: String,
});

const form = useForm({
    username: '',
    password: '',
    remember: false,
});

const submit = () => {
    form.transform((data) => ({
        ...data,
        remember: form.remember ? 'on' : '',
    })).post(route('login'), {
        onFinish: () => form.reset('password'),
    });
};
</script>

<template>
    <Head :title="$t('ui.login.page_title')" />

    <AuthenticationCard>
        <template #logo>
            <Logo />
        </template>

        <div v-if="status" class="mb-4 font-medium text-sm text-green-600">
            {{ status }}
        </div>

        <h1 class="text-black">{{ $t('ui.login.page_title') }}</h1>
        <p>{{ $t('ui.login.page_info') }}</p>

        <form @submit.prevent="submit" class="mt-10">
            <TextInput
                id="username"
                v-model="form.username"
                required
                autofocus
                autocomplete="username"
                :label="$t('ui.login.username_label')"
                :error="form.errors.username"
            />

            <TextInput
                id="password"
                v-model="form.password"
                type="password"
                class="mt-6"
                required
                autocomplete="current-password"
                :label="$t('ui.login.password_label')"
                :message="form.errors.password"
            />

            <div class="mt-6">
                <label class="flex items-center">
                    <Checkbox v-model:checked="form.remember" name="remember" />
                    <span class="ml-2 text-sm">
                        {{ $t('ui.login.remember_me_label') }}
                    </span>
                </label>
            </div>

            <div class="flex items-center justify-end mt-6">
                <PrimaryButton
                    :title="$t('ui.login.login_button')"
                    :class="{'opacity-25': form.processing}"
                    :disabled="form.processing"
                >
                    {{ $t('ui.login.login_button') }}
                </PrimaryButton>
            </div>
        </form>
    </AuthenticationCard>
</template>
